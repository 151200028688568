import React, {useState, useContext, useEffect} from 'react';
import { IonContent, IonButtons, IonBackButton, IonHeader, IonPage, IonToolbar, IonRouterLink } from '@ionic/react';
import { AppContext } from '../../../../State';
import { useParams } from 'react-router';
import KeyboardUtils from '../../../../utils/keyboardUtil';
import TextareaAutosize from 'react-textarea-autosize';
import Analytics from '../../../../services/Analytics'


type Props = {
  affirmationCycleCount: number
  endCallback: Function
  statement?: string
}

const Breath: React.FC<Props> = ({affirmationCycleCount, endCallback}) => {
  const [counter, setCounter] = useState(9);

  const backgroundColorClass = () => {
    switch(affirmationCycleCount){
      case 2:
        return "emotional-granularity-bg";
      case 4:
        return "shame-bg";
      case 6:
        return "neuroplasticity-bg";
    }
  }

  useEffect(() => {
    if (counter > 1)
    {
      setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setTimeout(() => endCallback(), 1000);
    }
  }, [counter]);

  return (
    <div className={backgroundColorClass() + " breath ion-padding"}>
      { affirmationCycleCount===2 && (<p>Take some deep breaths...</p>)}
      { affirmationCycleCount>2 && (<p>Take some more deep breaths...</p>)}
      <h1 className="huge-number">{counter}</h1>
      <div></div>
    </div>
  )
}

const SayAffirmation: React.FC<Props> = ({affirmationCycleCount, endCallback, statement}) => {
  const backgroundColorClass = () => {
    switch(affirmationCycleCount){
      case 1:
        return "emotional-granularity-bg";
      case 3:
        return "shame-bg";
      case 5:
        return "neuroplasticity-bg";
    }
  }
  return (
    <div className={backgroundColorClass() + " say-affirmation ion-padding"}>
      { affirmationCycleCount===1 && (<p>Repeat this phrase to yourself...</p>)}
      { affirmationCycleCount===3 && (<p>Repeat the phrase again...</p>)}
      { affirmationCycleCount===5 && (<p>Repeat the phrase one last time...</p>)}
      
      <h1 className="xl">{statement}</h1>
      <IonRouterLink className="button" onClick={(e) => {endCallback()}}>I said it</IonRouterLink>
    </div>
  )
}



const DefineFeelings: React.FC = () => {

  const [showIntro, setShowIntro] = useState(true);
  const [showAffirmationChoices, setShowAffirmationChoices] = useState(false);
  const [showAffirmationCycle, setShowAffirmationCycle] = useState(false);
  const [affirmationCycle, setAffirmationCycle] = useState(1);
  const [showConclusion, setShowConclusion] = useState(false);
  const [showAddYourAffirmation, setShowAddYourAffirmation] = useState(false);
  const {state, dispatch} = useContext(AppContext);
  const [selectedStatement, setSelectedStatement] = useState(''); 

  let { id } = useParams();
  const hc = ((id:string)=>{
    return state.hardConversations.find((data:any) => { return data.id===id});
  })(id);

  KeyboardUtils.showAccessoryBar();

  const updateAffirmationStatement = function(affirmationStatement: any) {
    dispatch({
      type: 'setAffirmationStatement',
      payload: {
        id: hc.id,
        affirmationStatement: affirmationStatement
      }
    });
  }

  useEffect(() => { 
    if (hc.affirmationStatement) {
      setShowAddYourAffirmation(true);
    }
  }, []);

  useEffect(() => { 
    if (selectedStatement) {
      navigateInPage('affirmationCycle');
    }
  }, [selectedStatement]);


  const handleChange = function(evt: any) {
    if (evt !== null && evt.target !==  null) {
      updateAffirmationStatement(evt.target.value);
    }
  };

  const selectStatement = (evt?:any, custom?:boolean) => {
    if (custom) {
      setSelectedStatement(hc.affirmationStatement);
    } else if (evt !== null && evt.target !==  null) {
      setSelectedStatement(evt.target.innerText);
    }
  }

  const affirmationState = () => {
    if (affirmationCycle >= 7) {
      setAffirmationCycle(0);
      navigateInPage('conclusion');
    } else {
      if ( affirmationCycle%2 === 0) {
        return <Breath affirmationCycleCount={affirmationCycle} endCallback={()=>{setAffirmationCycle(affirmationCycle+1)}} />;
      } else {
        return <SayAffirmation affirmationCycleCount={affirmationCycle} endCallback={()=>{setAffirmationCycle(affirmationCycle+1)}} statement={selectedStatement} />;
      }
    }
  }


  const navigateInPage = function(navigateTo : string, event? : any,) {
    Analytics.logEvent({
      name: "screen_view",
      params : {
        screen_name: "Affirm Your Worth - " + navigateTo
      }
    });
    if (event !== undefined) {
      event.preventDefault();
    }
    switch(navigateTo) {
      case 'intro':
        setShowIntro(true);
        setShowAffirmationChoices(false);
        setShowAffirmationCycle(false);
        setShowConclusion(false);
        break;
      case 'affirmationChoices':
        setShowIntro(false);
        setShowAffirmationChoices(true);
        setShowAffirmationCycle(false);
        setShowConclusion(false);
        break;
      case 'affirmationCycle':
        setShowAffirmationChoices(false);
        setShowAffirmationCycle(true);
        setShowConclusion(false);
        setShowIntro(false);
        setAffirmationCycle(1);
        break;
      case 'conclusion':
        setShowAffirmationCycle(false);
        setShowAffirmationChoices(false);
        setShowConclusion(true);
        setShowIntro(false);
        break;
    }
  }

  return (
    <IonPage className="affirm-your-worth">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="white-bg">
        { showIntro && (<div className="intro ion-padding shame-bg bg-pink-blob align-bottom">
          <div className="scroll-container">
            <p>Let’s regain control and affirm your worth.</p>
            <h2>Positive self talk and positive reappraisal  create healthy psychological distance from your feelings so you can regain perspective and stop beating yourself up.</h2>
            <IonRouterLink className="button" onClick={(e) => {navigateInPage('affirmationChoices', e)}}>Get Started</IonRouterLink>
          </div>
        </div>)}
        { showAffirmationChoices && (<div className={"affirmation-choices ion-padding"}>
          <p>We’re going to repeat a phrase that will help us take back our own power. Select a phrase below or write your own.</p>
          <ul className="affirmations">
            <li className="emotional-granularity-bg" onClick={(e) => {selectStatement(e)}}>
              <h2>I am good at my job, I am smart. I am valuable.</h2>
            </li>
            <li className="neuroplasticity-bg" onClick={(e) => {selectStatement(e)}}>
              <h2>Feelings are not facts. I am not a shitty human, this is just a shitty moment. </h2>
            </li>
            <li className="shame-bg" onClick={(e) => {selectStatement(e)}}>
              <h2>I matter. My emotions matter. I am valuable.</h2>
            </li>
            { showAddYourAffirmation && (<li className="frustration-bg">
              <TextareaAutosize 
                
                defaultValue={hc.affirmationStatement}
                onChange={handleChange}
                placeholder="Type your own affirmation here "
                className="magic-text-area"
                no-blur="true" />
                <IonRouterLink className="button" onClick={(e) => {selectStatement(e, true)}}>Use your statement</IonRouterLink>
            </li>)}
            
            { !showAddYourAffirmation && (<li className="add-your-affirmation">
              <div onClick={()=> {setShowAddYourAffirmation(true)}}><img src="/assets/add-button.svg" alt="add your own affirmation button" /></div>
            </li>)}
            
          </ul>
        </div>)}
      { showAffirmationCycle && affirmationState()}
      { showConclusion && (
        <div className="conclusion align-bottom ion-padding self-criticism-bg">
          <div className="scroll-container">
            <h1 className="xxl">You're the shit.</h1>
            <IonRouterLink className="button" onClick={(e) => {navigateInPage('affirmationChoices', e)}}>Do That Again</IonRouterLink>
          </div>
        </div>
      )}
        
      </IonContent>
    </IonPage>
  );
};

export default DefineFeelings;