import React, { useState, useContext, useRef, useEffect } from 'react';
import { IonContent, IonRouterLink, IonButtons, IonModal, IonAlert, IonBackButton, IonHeader, IonPage, IonToolbar, IonFooter, IonIcon } from '@ionic/react';
import TextareaAutosize from 'react-textarea-autosize';
import { AppContext } from '../../../../State';
import { useParams } from 'react-router';
import KeyboardUtils from '../../../../utils/keyboardUtil';
import { close } from 'ionicons/icons';
import Analytics from '../../../../services/Analytics';
import { v4 as uuidv4 } from 'uuid';


const ClassNames = require('classnames');

type ListProps = {
  mode? : string
}

interface List {
  id: number,
  name: string
}

interface state {
  list: Array<Object>
}
 
const List: React.FC<ListProps> = ({mode}) => {
  const {state, dispatch} = useContext(AppContext);
  
  let { id } = useParams();
  const hc = ((id:string)=>{
    return state.hardConversations.find((data:any) => { return data.id===id});
  })(id);

  const useFocus = () => {
    const htmlElRef: any = useRef(null)
    
    const setFocus = () => {
      if (htmlElRef.current != null) {
        htmlElRef.current && htmlElRef.current.focus()
      }
    }
    return [ htmlElRef, setFocus ] 
  }
  const [inputRef, setInputFocus] = useFocus();


  const updateList = function(list: Array<List>) {
    dispatch({
      type: 'setList',
      payload: {
        id: hc.id,
        list: list
      }
    });
  }
  
  const addItem = () => {
    updateList([
      ...hc.list,
      {
        id: hc.list.length,
        key: uuidv4(),
        name: ""
      }
    ]);
  };

  const updateItem = (itemId : any, itemValue : string) => {
    let localItems = hc.list;
    localItems[itemId] = {
      id: itemId,
      key: localItems[itemId].key ? localItems[itemId].key : uuidv4(),
      name: itemValue
    };

    updateList(localItems)
  }

  const deleteItem = (itemId : any) => {
    let localItems = hc.list;
    localItems.splice(itemId, 1);
    let newItems = localItems.map(function(currentValue : any, index: Number){
      return {
        id: index,
        key: currentValue.key,
        name: currentValue.name
      }
    });
    updateList(newItems);
  }

  const containerClasses = ClassNames(
    'ion-padding',
    'make-a-list',
    {
      'emotional-granularity-bg': mode === 'refine',
      'shame-bg': mode === 'make'
    }
  )

  useEffect(() => {
    if (hc && hc.list === undefined) {
      dispatch({
        type: 'initializeList',
        payload: {
          id: hc.id,
        }
      });
    }
  }, []);

  return(
    <div className={containerClasses}>
        <div>
          { mode === 'make' && (<p>Think about that upcoming hard conversation, what’s imporant to address.</p>)}
          { mode === 'refine' && (<p>Now let’s seperate the feelings from the facts. Try to remove any items that are just your reaction to the facts and purpose of the meeting.</p>)}
          <form className="list">
            {hc.list.map((item:any) => (
              <div className="list-item" key={item.key}>
                <TextareaAutosize 
                  className={"list-item-name magic-text-area"}
                  name="item"
                  type="text"
                  defaultValue={item.name}
                  onChange={e => updateItem(item.id, e.target.value)}
                  placeholder="Type a talking point"
                  disabled={(mode==='refine')}
                />
                <a className={"remove-item"} onClick={e => deleteItem(item.id)}><img alt="remove item" src="/assets/list-remove-item.svg" / ></a>
              </div>
            ))}
          </form>
          { mode === 'make' && (<button className={"add-item"} onClick={addItem}><img alt="sort item" src="/assets/list-add-item.svg" / ></button>)}
        </div>
    </div>
  )
}


const MakeAList: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [showMakeList, setShowMakeList] = useState(false);
  const [showRefineList, setShowRefineList] = useState(false);
  const [showConclusion, setShowConclusion] = useState(false);
  const [showListError, setShowListError] = useState(false);
  const [pageNavPrev, setPageNavPrev] = useState('');
  const [pageNavNext, setPageNavNext] = useState('');
  const {state, dispatch} = useContext(AppContext);

  let { id } = useParams();
  const hc = ((id:string)=>{
    return state.hardConversations.find((data:any) => { return data.id===id});
  })(id);

  KeyboardUtils.showAccessoryBar();

  const validateList = () => {
    let valid = false;
    hc.list.map((listItem:any) => {
      console.log(listItem.name)
      if (listItem.name.length > 0) {
        valid = true
      }
    })
    return valid;
  }

  const navigateInPage = function(navigateTo:string, event?:any) {
    Analytics.logEvent({
      name: "screen_view",
      params : {
        screen_name: "Make a List - " + navigateTo
      }
    });
    if (event !== undefined) {
      event.preventDefault();
    }
    switch(navigateTo) {
      case 'intro':
        setShowIntro(true);
        setShowMakeList(false);
        setShowRefineList(false);
        setShowConclusion(false);
        setPageNavNext('');
        setPageNavPrev('');
        break;
      case 'startList':
        setShowIntro(false);
        setShowMakeList(true);
        setShowRefineList(false);
        setShowConclusion(false);
        setPageNavNext('refineList');
        setPageNavPrev('intro');
        break;
      case 'refineList':
        if (validateList()) {
          setShowMakeList(false);
          setShowRefineList(true);
          setShowConclusion(false);
          setShowIntro(false);
          setPageNavNext('conclusion');
          setPageNavPrev('startList');
        } else {
          setShowListError(true);
        }
        break;
      case 'conclusion':
        setShowRefineList(false);
        setShowMakeList(false);
        setShowIntro(false);
        setShowConclusion(true);
        setPageNavNext('');
        setPageNavPrev('');
        break;
    }
  }

  

  useEffect(() => { 
    if (hc.listComplete) {
      navigateInPage('conclusion');
    }
  }, []);

  useEffect(() => { 
    if (showConclusion === true) {
      dispatch({
        type: 'setListComplete',
        payload: {
          id: hc.id,
          listComplete: true
        }
      });
    }
    
  }, [showConclusion]);




  return (
    <IonPage className="vulnerability-bg prepare-list">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
      { showIntro && ( <div className="align-bottom intro ion-padding">
        <div className="scroll-container">
          <h1>Make a list that focuses on facts</h1>
          <p>The context matters and you’re feelings matter, but for this exercise we need to separate feelings from facts.</p>
          <p>So you can stay focused, make a list of your fact based needs.</p>
          <IonRouterLink className="button" onClick={(e) => {navigateInPage('startList', e)}}>Get Started</IonRouterLink>
        </div>
      </div>)}
      { showMakeList && <List mode="make" />}
      { showRefineList && <List mode="refine" />}
      { showConclusion && (
        <div className="conclusion">
          <div className="vulnerability-bg ion-padding section">
            <h2>Focus on these facts to prepare for the conversation.</h2>
            <div className="activity-outcomes">
              <ul className={"list"}>
                {hc.list.map((item:any) => (
                  <li>{item.name}</li>
                ))}
              </ul>
              <a href="#" className="edit-activity" onClick={(e) => {navigateInPage('startList', e)}}>Edit my list</a>
            </div>
            <p>Your feelings are valid.  It's not that your emotions don't matter, it's that emotions are the easiest for others to manipulate and for you to get overwhelmed by.</p>
            <p>Remember the context of the conversation matters. What are the power dynamics?  Is this a family member or romantic partner? Is the other person coming from a position of social privilege? All of that is going to impact your emotions.</p>
            <p>To have a productive conversation, focusing on the facts helps create clarity, understanding, and emotional regulation.</p>
          </div>
          <div className="shame-bg ion-padding section">
            <h1 className="xxl">Feelings are not facts</h1>
            <p>You might be feeling super overwhelmed by big feelings. Remind yourself that no matter how intense they feel, underneath them is a need or a fact that you can address directly.</p>
          </div>
        </div>
      )}
      <IonAlert
          isOpen={showListError}
          onDidDismiss={() => setShowListError(false)}
          cssClass='my-custom-class'
          header={'Add a talking point'}
          subHeader={''}
          message={'Please add at least one talking point to your list.'}
          buttons={['OK']}
        />
        
      </IonContent>
      { (pageNavNext || pageNavPrev) &&
        (
          <IonFooter className="page-navigation-wrapper">
            <div className="page-navigation">
              { pageNavPrev && (<a href="#" className="prev" onClick={(e) => {navigateInPage(pageNavPrev, e)}}><img src="/assets/page-nav-arrow.svg" /></a> ) }

              <IonRouterLink className={"button secondary helpme"} onClick={() => setShowModal(true)}>Help me!</IonRouterLink>
              <IonModal isOpen={showModal} cssClass='modal-padding'>
                <IonContent className="ion-padding"> 
                { showMakeList && (<div><p>Let’s fist zoom out, what are the main things you are trying to achieve.</p>  
                  <p>Some good examples would be:</p>
                  <h2 className="emotional-granularity">My direct manager has been speaking to me in a way that I find disrespectful.</h2>
                  <h2 className="neuroplasticity">I’ve been asked to work overtime very often.</h2>
                  <h2 className="anxiety">This project doesn’t feel like it has clearly defined leadership.</h2>
                  <h2 className="self-criticism">I've been asking for help recently and feel unheard.</h2></div>)}

                { showRefineList && (
                  <div>
                    <p>The goal here is to refine the list by focusing only on the facts. There's a lot of thoughts that enter our minds in a hard conversation or difficult situation. We mix up the facts and the things we can control with our reaction and emotions that are tied to those facts.</p>
                    <p>An example of items you would want to remove:</p>
                    <h2 className="anxiety">I get so angry whenever you disrupt my work</h2>
                    <p>An alternative way to express the underlying need might be:</p>
                    <h2 className="shame">I find my work being disrupted often with other requests and questions, can we do something to schedule heads down work time?</h2>
                  </div>
                )}
                
                  <IonRouterLink className={"button close"} onClick={() => setShowModal(false)}><IonIcon icon={close}/></IonRouterLink>
                </IonContent>
              </IonModal> 
            
              { pageNavNext && (<a href="#" className="next" onClick={(e) => {navigateInPage(pageNavNext, e)}}><img src="/assets/page-nav-arrow.svg" /></a> ) }
            </div>
          </IonFooter>
        )
      }
    </IonPage>
  );
};

export default MakeAList;