import React from 'react';
import { IonContent, IonButtons, IonBackButton, IonHeader, IonPage, IonToolbar, IonRouterLink } from '@ionic/react';
import { useParams } from 'react-router';

const ReboundList: React.FC = () => {
  let { id } = useParams();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        <div className="align-bottom">
          <div className="scroll-container">
            <h2>Hard conversations can be rough. Here’s some ways to help you rebound after a convo.</h2>
            <div className="button-list">
              <IonRouterLink className="button" routerLink={"/guides/hard-conversations/"+id+"/rebound/define-your-feelings"} routerDirection="forward">Define Your Feelings</IonRouterLink>
              <IonRouterLink className="button" routerLink={"/guides/hard-conversations/"+id+"/rebound/affirm-your-worth"} routerDirection="forward">Affirm Your Worth</IonRouterLink>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ReboundList;