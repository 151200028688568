import { isPlatform, getPlatforms } from '@ionic/react';
import "@capacitor-community/firebase-analytics";
import { Plugins } from "@capacitor/core";
const { FirebaseAnalytics } = Plugins;

if ( !isPlatform('ios') ) {
  FirebaseAnalytics.initializeFirebase({
    apiKey: "AIzaSyDBZJ9d6HNncItMVZQxQXtc7oCI45FU-rw",
    authDomain: "wildfeelings-app.firebaseapp.com",
    databaseURL: "https://wildfeelings-app.firebaseio.com",
    projectId: "wildfeelings-app",
    storageBucket: "wildfeelings-app.appspot.com",
    messagingSenderId: "570376432753",
    appId: "1:570376432753:web:5e6e2bb50a9fabe2063fd0",
    measurementId: "G-KRM4RBH1S9"
  });
}

export default FirebaseAnalytics;