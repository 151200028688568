import React, { useState, useEffect } from 'react';
import { IonContent, IonGrid, IonRow, IonCol, IonPage, IonRouterLink, IonModal } from '@ionic/react';
import ReactDOM from "react-dom";

import './Home.css';


const Home: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    width > 1000 && handleSideNavToggle();
  },[width]);

  function handleSideNavToggle() {
    setShowModal(true);
  }


  return (
    <IonPage className="home-bg">
      <IonContent className="ion-padding">
        <div className="align-bottom">
          <div className="scroll-container">
            <IonGrid>
              <IonRow>
                <IonCol>
                  <img src="assets/welcome-logo.svg" alt="Wild Feelings Logo" />
                  <p>We are here to expand your understanding of emotions and better equip you with the tools you need to thrive.</p>
                  <p>We’re starting with hard conversations, because we all have a hard time with those.</p>
                  <p>This is an open beta prototype of our app. Please let us know your feedback <a href="https://forms.gle/MBbpzVD7UQ14CwYc6" target="_blank">here.</a></p>
                  <IonRouterLink className={"button w-o-b conversations"}  routerLink={"/hard-conversations"} routerDirection="forward">Hard Conversations</IonRouterLink>
                  <IonRouterLink className={"button w-o-b guides"}  routerLink={"/feelings"} routerDirection="forward">View Feelings</IonRouterLink>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonModal isOpen={showModal} cssClass='modal-padding'>
                <IonContent className="ion-padding"> 
                
                  <div>
                    <h2>This app was designed and built for mobile devices.</h2>
                    <p>You are seeing this message because you're using a larger screen device, like a laptop or desktop computer. The design of this website was not intended for that use, but you can still use this website. We are actively trying to make the large screen design usable, but use at your own risk.</p>
                    <IonRouterLink className={"button w-o-b"}  onClick={() => setShowModal(false)}>Understood!</IonRouterLink>
                  </div>
              
                </IonContent>
              </IonModal> 
          </div>
        </div>  
      </IonContent>
    </IonPage>
  );
  
}

export default Home;