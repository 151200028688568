import React, { useContext } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonRouterLink, IonButton, IonIcon } from '@ionic/react';
import { AppContext } from '../../../State';
import { useParams } from 'react-router';
import {chevronBack} from 'ionicons/icons';

const HardConversation: React.FC = () => {
  const {state, dispatch} = useContext(AppContext);  

  let { id } = useParams();
  const hc = ((id:string)=>{
    return state.hardConversations.find((data:any) => { return data.id===id});
  })(id);
  
  return (
    <IonPage className="home-bg">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton routerLink="/hard-conversations" routerDirection="back" className="fake-back-button"><IonIcon icon={chevronBack} /> Conversations</IonButton>
          </IonButtons>
          <IonTitle>Wild Feelings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        <h1>{hc.name}</h1>
        <p>What would be most helpful right now?</p>
        <IonRouterLink className="home-card bg-button-blob" routerLink={"/guides/hard-conversations/"+id+"/feelings"} routerDirection="forward">Learn which feelings might be activated</IonRouterLink>
        <IonRouterLink className="home-card bg-button-squiggle" routerLink={"/guides/hard-conversations/"+id+"/prepare"} routerDirection="forward">Get prepared For a hard conversation</IonRouterLink>
        <IonRouterLink className="home-card bg-button-spikes" routerLink={"/guides/hard-conversations/"+id+"/rebound"} routerDirection="forward">Rebound from a hard conversation</IonRouterLink>
      </IonContent>
    </IonPage>
  );
}


export default HardConversation;