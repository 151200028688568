import React, { useContext, useState } from 'react';
import { IonContent, IonPage, IonRouterLink } from '@ionic/react';
import { AppContext } from '../../../../State';
import { v4 as uuidv4 } from 'uuid';
import Analytics from '../../../../services/Analytics'


const GuideHardConversationsIntro: React.FC = () => {
  Analytics.logEvent({
    name: "screen_view",
    params : {
      screen_name: "Intro"
    }
  });

  const {state, dispatch} = useContext(AppContext);
  const [newHCId, setNewHCId] = useState(uuidv4());

  const createHardConversation = () => {
    dispatch({
      type: 'addHardConversation',
      id: newHCId
    });
  }
  
  return (
    <IonPage className="anxiety-bg">
      <IonContent class="ion-padding">
        <div className="align-bottom">
          <div className="scroll-container">
            <h1>Hard Conversations</h1>
            <p>You’re not alone. Conflict is challenging for everyone. Learning to navigate emotionally activating moments is a skill. </p>
            <IonRouterLink className="button" onClick={createHardConversation} routerLink={"/guides/hard-conversations/"+newHCId+"/create/"}>I'm ready to learn</IonRouterLink>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default GuideHardConversationsIntro;
