import React, { useState, useEffect } from 'react';
import { IonContent, IonRouterLink, IonButtons, IonBackButton, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import Analytics from '../../../../services/Analytics'

type Props = {
  endCallback: Function
}

const Inhale: React.FC<Props> = ({endCallback}) => {
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    if (counter > 1)
    {
      setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setTimeout(() => endCallback(), 1000);
    }
  }, [counter]);

  return (
    <div className="breathe-in">
      <h1 className="huge-number">{counter}</h1>
      <h2>Breath in deeply through your nose.</h2>
    </div>
  )
}

const Hold: React.FC<Props> = ({endCallback}) => {
  const [counter, setCounter] = useState(7);
  React.useEffect(() => {
    if (counter > 1)
    {
      setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setTimeout(() => endCallback(), 1000);
    }
  }, [counter]);

  return (
    <div className="breathe-hold">
      <h1 className="huge-number">{counter}</h1>
      <h2>Hold that breath fully in your lungs.</h2>
    </div>
  )
}

const Exhale: React.FC<Props> = ({endCallback}) => {
  const [counter, setCounter] = useState(9);
  React.useEffect(() => {
    if (counter > 1)
    {
      setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setTimeout(() => endCallback(), 1000);
    }
  }, [counter]);

  return (
    <div className="breathe-out">
      <h1 className="huge-number">{counter}</h1>
      <h2>Exhale slowly.</h2>
    </div>
  )
}

const Breathe: React.FC = () => {
  let { id } = useParams();
  const [showInhale, setShowInhale] = useState(false);
  const [showHold, setShowHold] = useState(false);
  const [showExhale, setShowExhale] = useState(false);
  const [showStart, setShowStart] = useState(true);
  const [cycleCounter, setCycleCounter] = useState(0);
  const cycleMax = 4;
  Analytics.logEvent({
    name: "screen_view",
    params : {
      screen_name: "Breathe"
    }
  });
  const startBreathing = () => {
    startCycle();
  }
  const startCycle = () => {
    setCycleCounter(cycleCounter + 1);
    setShowInhale(true);
    setShowStart(false);
  }
  const endInhale = () => {
    setShowInhale(false);
    setShowHold(true);
  }
  const endHold = () => {
    setShowExhale(true);
    setShowHold(false);
  }
  const endExhale = () => {
    setShowExhale(false);
    if (cycleCounter >= cycleMax) {
      resetBreathing();
    } else {
      startCycle();
    }
  }
  const resetBreathing = () => {
    setShowHold(false);
    setShowInhale(false);
    setShowExhale(false);
    setCycleCounter(0);
    setShowStart(true);
  }


  return (
    <IonPage className={"self-criticism-bg breathe"}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        { showStart && (<div className="align-bottom ion-padding bg-breathe ">
          <div className="scroll-container">
            <h1 className="xl">Breathing helps you to lower anxiety, find your focus, and regain control</h1>
            <p>Get comfortable & hit "start" when you're ready to practice guided breathing.</p>
            <IonRouterLink className="button" onClick={startBreathing}>Start</IonRouterLink>
          </div>
        </div>)}

        { !showStart && (<div className="ion-padding align-bottom">
          <div className="scroll-container">
          
            { showInhale && <Inhale endCallback={endInhale} /> }
            { showHold && <Hold endCallback={endHold} /> }
            { showExhale && <Exhale endCallback={endExhale} /> }
            
            { (showInhale || showHold || showExhale) && (
            <div className="cycle-controls">
              <div className="cycle-counter">
                <p>This cycle repeats 4 times.</p>
                {[...Array(cycleCounter)].map((e, i) => <span className="cycle-count" key={i}>{i+1}</span>)}
              </div>
              <div className="reset-button">
                <IonRouterLink className="button" onClick={resetBreathing}>Start Over</IonRouterLink>
              </div>
            </div>)}
          </div>
        </div>)}
      </IonContent>
    </IonPage>
  );
};

export default Breathe;