import React, { useState, useRef, useContext, useEffect } from 'react';
import { IonContent, IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonModal, IonRouterLink, IonIcon, IonFooter, IonAlert } from '@ionic/react';
import TextareaAutosize from 'react-textarea-autosize';
import { AppContext } from '../../../State';
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";
import KeyboardUtils from '../../../utils/keyboardUtil';
import {close} from 'ionicons/icons';
import Analytics from '../../../services/Analytics'


const Name: React.FC = () => {
  Analytics.logEvent({
    name: "screen_view",
    params : {
      screen_name: "Create Conversation - Name"
    }
  });
  const {state, dispatch} = useContext(AppContext);

  let { id } = useParams();
  const hc = ((id:string)=>{
    return state.hardConversations.find((data:any) => { return data.id===id});
  })(id);

  const updateName = function(name: string) {
    dispatch({
      type: 'setName',
      payload: {
        id: hc.id,
        name: name
      }
    });
  }

  const handleChange = function(evt: any) {
    if (evt !== null && evt.target !==  null) {
      updateName(evt.target.value);
    }
  };
  
  return (
    <div className={"ion-padding emotional-hijacking-bg full-height"}>
      <div className="">
        <h2>Let’s name this upcoming conversation.</h2>
        <div id="emotions-list">
          <textarea 
          maxLength={60}
          defaultValue={hc.name} 
          onChange={handleChange}
          placeholder="Type a short name here"
          className="magic-text-area"
          no-blur />
        </div>
      </div>
    </div>
  );
};


const Worries: React.FC = () => {
  Analytics.logEvent({
    name: "screen_view",
    params : {
      screen_name: "Create Conversation - Worries"
    }
  });
  const [showModal, setShowModal] = useState(false);
  const {state, dispatch} = useContext(AppContext);

  let { id } = useParams();
  const hc = ((id:string)=>{
    return state.hardConversations.find((data:any) => { return data.id===id});
  })(id);


  const useFocus = () => {
    const htmlElRef: any = useRef(null)
    
    const setFocus = () => {
      if (htmlElRef.current != null) {
        htmlElRef.current && htmlElRef.current.focus()
      }
    }
    return [ htmlElRef, setFocus ] 
  }

  const [inputRef, setInputFocus] = useFocus();

  const updateWorryList = function(worries: string) {
    dispatch({
      type: 'setWorries',
      payload: {
        id: id,
        worries: worries
      }
    });
  }
  
  const loadUnstuckOption = function(evt: any) {
    if (evt !== null && evt.target !==  null) {
      updateWorryList(evt.target.innerHTML);
      setInputFocus();
    }
    setShowModal(false);
  };

  const handleChange = function(evt: any) {
    if (evt !== null && evt.target !==  null) {
      updateWorryList(evt.target.value);
    }
  };

  return (
    <div className="ion-padding self-criticism-bg full-height">
      <div className="">
        <h2>Conflict is hard for everybody. What does it make you worry about?</h2>
        <div id="worry-list">
          <form>
          <TextareaAutosize 
            defaultValue={hc.worries} 
            onChange={handleChange}
            ref={inputRef}
            placeholder="Type your worry here "
            className="magic-text-area"
            no-blur="true" />
          </form>
          
        </div>
      </div>
    </div>
  );
};


const Details: React.FC = () => {
  Analytics.logEvent({
    name: "screen_view",
    params : {
      screen_name: "Create Conversation - Details"
    }
  });
  const [showModal, setShowModal] = useState(false);
  const {state, dispatch} = useContext(AppContext);
  
  let { id } = useParams();
  const hc = ((id:string)=>{
    return state.hardConversations.find((data:any) => { return data.id===id});
  })(id);

  const useFocus = () => {
    const htmlElRef: any = useRef(null)
    
    const setFocus = () => {
      if (htmlElRef.current != null) {
        htmlElRef.current && htmlElRef.current.focus()
      }
    }
    return [ htmlElRef, setFocus ] 
  }

  const [inputRef, setInputFocus] = useFocus();

  const updateDetails = function(details: string) {
    dispatch({
      type: 'setDetails',
      payload: {
        id: id,
        details: details
      }
    });
  }

  const handleChange = function(evt: any) {
    if (evt !== null && evt.target !==  null) {
      updateDetails(evt.target.value);
    }
  };

  return (
    <div className={"ion-padding shame-bg full-height"}>
      <div className="">
        <h2>Tell us a little about an upcoming conversation you think might be hard.</h2>
        <div id="worry-list">
          <form>
          <TextareaAutosize 
            defaultValue={hc.details}
            onChange={handleChange}
            ref={inputRef}
            placeholder="Type some details here "
            className="magic-text-area"
            no-blur="true" />
          </form>
          
        </div>
      </div>  
    </div>
  );
};


const CreateConversation: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [showWorries, setShowWorries] = useState(false);
  const [showName, setShowName] = useState(false);
  const [showNameError, setShowNameError] = useState(false)
  const [pageNavPrev, setPageNavPrev] = useState('');
  const [pageNavNext, setPageNavNext] = useState('');
  const {state, dispatch} = useContext(AppContext);
  
  let { id } = useParams();
  const hc = ((id:string)=>{
    return state.hardConversations.find((data:any) => { return data.id===id});
  })(id);

  const existingHC = (()=>{
    return !!state.hardConversations.find((data:any) => { return data.active === true;});
  })();

  KeyboardUtils.showAccessoryBar();

  const history = useHistory();

  const navigateInPage = function(event : any, navigateTo : string) {
    console.log(navigateTo);
    event.preventDefault();
    switch(navigateTo) {
      case 'details':
        setShowDetails(true);
        setShowWorries(false);
        setPageNavNext('worries');
        setPageNavPrev('');
        break;
      case 'worries':
        setShowDetails(false);
        setShowWorries(true);
        setShowName(false);
        setPageNavNext('name');
        setPageNavPrev('details');
        break;
      case 'name':
        setShowDetails(false);
        setShowWorries(false);
        setShowName(true);
        setPageNavNext('conversation');
        setPageNavPrev('worries');
        break;
      case 'dashboard':
        setShowDetails(false);
        setShowWorries(false);
        setShowName(false);
        history.push('/home/')
        break;
      case 'conversation':
        if (hc.active) {
          Analytics.logEvent({
            name: "create_conversation"
          });
          setShowDetails(false);
          setShowWorries(false);
          setShowName(false);
          history.push('/guides/hard-conversations/'+id)
        } else {
          setShowNameError(true);
        }
        break;
    }
  }

  const updateWorries = function(worries: string) {
    dispatch({
      type: 'setWorries',
      payload: {
        id: id,
        worries: worries
      }
    });
  }

  const autofillWorryOption = function(evt: any) {
    if (evt !== null && evt.target !==  null) {
      updateWorries(evt.target.innerHTML);
    }
    setShowModal(false);
  };

  useEffect(() => { 
    setPageNavNext('worries');
    setPageNavPrev('dashboard')
  }, [state.hardConversations.length]);

  const possibleFeelings = ["Anger","Shame","Anxiety","Guilt","Helplessness","Curious","Hesitant","Scared","Exposed","Hopeless","Threatened","Lost","Shocked","Testy","Used","Spiteful","Hopeful","Excited","Grateful","Optimistic","Tender","Thankful","Offended","Ornery","Rebellious","Accused","Bitter","Aggravated","Hostile","Inept","Inferior","Insignificant","Stupid","Squashed","Ignored","Guilty","Incredulous","Exhilarated","Insecure","Sheepish","Resentful","Satisfied","Uplifted"];

  return (
    <IonPage className={'anxiety-bg define-your-feelings'}>
      {existingHC && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/home" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent key={id}>
      { showDetails && <Details />}
      { showWorries && <Worries />}
      { showName && <Name />}
      <IonAlert
          isOpen={showNameError}
          onDidDismiss={() => setShowNameError(false)}
          cssClass='my-custom-class'
          header={'We need a name'}
          subHeader={''}
          message={'Please add a name to this conversation so you can keep track of your excersizes and info.'}
          buttons={['OK']}
        />
      </IonContent>
      { (pageNavNext || pageNavPrev) &&
        (
          <IonFooter className="page-navigation-wrapper">
            <div className="page-navigation">
              { pageNavPrev && (<a href="#" className="prev" onClick={(e) => {navigateInPage(e, pageNavPrev)}}><img src="/assets/page-nav-arrow.svg" /></a> ) }
              <IonRouterLink className={"button secondary helpme"} onClick={() => setShowModal(true)}>Help me!</IonRouterLink>
              <IonModal isOpen={showModal} cssClass='modal-padding'>
                <IonContent className="ion-padding">
                  
                  { showDetails && (<div><p>Just write some information about the upcoming conversation, who you are having it with, and maybe some context. This is just for your own reflection.</p></div>)}
                  
                  { showWorries && (<div className="unstuck-options">
                    <p className="learned-helplessness" onClick={autofillWorryOption}>
                      I’m scared of what people think of me.
                    </p>
                    <p className="shame" onClick={autofillWorryOption}>
                      I hate conflict, so I shut down and never get my point across.
                    </p>
                    <p className="anger" onClick={autofillWorryOption}>
                      I get angry! Then I worry about how I behaved.
                    </p>
                    <p className="anxiety" onClick={autofillWorryOption}>
                      I worry about how my reaction will be viewed or policed by others 
                    </p>
                  </div>)}

                  { showName && (<div><p>If you're not sure what to name the conversation, maybe come up with a code name for it.</p></div>)}

                  <IonRouterLink className="button close" onClick={() => setShowModal(false)}><IonIcon icon={close}/></IonRouterLink>
                </IonContent>
              </IonModal> 
              { pageNavNext && (<a href="#" className="next" onClick={(e) => {navigateInPage(e, pageNavNext)}}><img src="/assets/page-nav-arrow.svg" /></a> ) }
            </div>
          </IonFooter>
        )
      }
    </IonPage>
  );
};

export default CreateConversation;