import React, { useState, useEffect, useContext } from 'react';
import { IonContent, IonRouterLink, IonButtons, IonFooter, IonBackButton, IonHeader, IonPage, IonToolbar, IonModal, IonIcon } from '@ionic/react';
import TextareaAutosize from 'react-textarea-autosize';
import KeyboardUtils from '../../../../utils/keyboardUtil';
import {close} from 'ionicons/icons';
import { AppContext } from '../../../../State';
import { useParams } from 'react-router';
import Analytics from '../../../../services/Analytics'


type PromptProps = {
  title?: string,
  description?: string,
  placeholder?: string,
  defaultValue?: string,
  handleChangeCallback?: Function
}

const Prompt: React.FC<PromptProps> = ({title, description, placeholder, defaultValue, handleChangeCallback}) => {
  return(
    <div className={"ion-padding"}>
      {title && (<h2>{title}</h2>)}
      {description && (<p>{description}</p>)}
      <form>
      <TextareaAutosize 
        
        defaultValue={defaultValue}
        onChange={(e) => {handleChangeCallback && handleChangeCallback(e)}}
        placeholder={placeholder}
        className="magic-text-area"
        no-blur="true" />
      </form>
    </div>
  )
}

const BattleShame: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [showNegativeThoughts, setShowNegativeThoughts] = useState(false);
  const [showShamePower, setShowShamePower] = useState(false);
  const [showThoughtOrigins, setShowThoughtOrigins] = useState(false);
  const [showThoughtImpacts, setShowThoughtImpacts] = useState(false);
  const [showSelfCompassion, setSowSelfCompassion] = useState(false);
  const [showConclusion, setShowConclusion] = useState(false);
  const [pageNavPrev, setPageNavPrev] = useState('');
  const [pageNavNext, setPageNavNext] = useState('');
  const {state, dispatch} = useContext(AppContext);

  let { id } = useParams();
  const hc = ((id:string)=>{
    return state.hardConversations.find((data:any) => { return data.id===id});
  })(id);

  KeyboardUtils.showAccessoryBar();

  const navigateInPage = function(navigateTo : string, event? : any,) {
    Analytics.logEvent({
      name: "screen_view",
      params : {
        screen_name: "Battle Shame - " + navigateTo
      }
    });
    if (event !== undefined) {
      event.preventDefault();
    }
    setShowIntro(false);
    setShowNegativeThoughts(false);
    setShowShamePower(false);
    setShowThoughtOrigins(false);
    setShowThoughtImpacts(false);
    setSowSelfCompassion(false);
    setShowConclusion(false);

    switch(navigateTo) {
      case 'intro':
        setShowIntro(true);
        setPageNavNext('');
        setPageNavPrev('');
        break;
      case 'negativeThoughts':
        setShowNegativeThoughts(true);
        setPageNavNext('shamePower');
        setPageNavPrev('intro');
        break;
      case 'shamePower':
        setShowShamePower(true);
        setPageNavNext('thoughtOrigins');
        setPageNavPrev('negativeThoughts');
        break;
      case 'thoughtOrigins':
        setShowThoughtOrigins(true);
        setPageNavNext('thoughtImpacts');
        setPageNavPrev('shamePower');
        break;
      case 'thoughtImpacts':
        setShowThoughtImpacts(true);
        setPageNavNext('selfCompassion');
        setPageNavPrev('thoughtOrigins');
        break;
      case 'selfCompassion':
        setSowSelfCompassion(true);
        setPageNavNext('conclusion');
        setPageNavPrev('thoughtImpacts');
        break;
      case 'conclusion':
        setShowConclusion(true);
        setPageNavNext('');
        setPageNavPrev('');
        break;
    }
  }

  const handleChangeNegativeThoughts = function(evt: any) {
    if (evt !== null && evt.target !==  null) {
      dispatch({
        type: 'setNegativeThoughts',
        payload: {
          id: hc.id,
          negativeThoughts: evt.target.value
        }
      });
    }
  };

  const handleChangeThoughtOrigins = function(evt: any) {
    if (evt !== null && evt.target !==  null) {
      dispatch({
        type: 'setThoughtOrigins',
        payload: {
          id: hc.id,
          thoughtOrigins: evt.target.value
        }
      });
    }
  };

  const handleChangeThoughtImpacts = function(evt: any) {
    if (evt !== null && evt.target !==  null) {
      dispatch({
        type: 'setThoughtImpacts',
        payload: {
          id: hc.id,
          thoughtImpacts: evt.target.value
        }
      });
    }
  };

  const handleChangeSelfCompassion = function(evt: any) {
    if (evt !== null && evt.target !==  null) {
      dispatch({
        type: 'setSelfCompassion',
        payload: {
          id: hc.id,
          selfCompassion: evt.target.value
        }
      });
    }
  };


  useEffect(() => { 
    if (hc.battleShameComplete) {
      navigateInPage('conclusion');
    }
  }, []);

  useEffect(() => { 
    console.log("show conclusion?")
    if (showConclusion === true) {
      dispatch({
        type: 'setBattleShameComplete',
        payload: {
          id: hc.id,
          battleShameComplete: true
        }
      });
    }
  }, [showConclusion]);


  return (
    <IonPage className={"battle-shame neuroplasticity-bg"}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        { showIntro && (
          <div className="align-bottom intro ion-padding vulnerability-bg">
            <div className="scroll-container">
              <h1>What’s Your Shame Story?</h1>
              <p>When you feel vulnerable, Shame creeps in to whisper scary things full of self-doubt and self-criticism.</p>
              <p>Shame tells you bias stories that mess with you self worth and confidence.</p>
              <p>The first step to more control is identifying what story Shame tells you.</p>
              <IonRouterLink className="button" onClick={(e) => {navigateInPage('negativeThoughts', e)}}>Get Started</IonRouterLink>
            </div>
          </div>
        )}

        { showNegativeThoughts && (<Prompt title="" description="When you feel vulnerable, what are the negative thoughts you think about yourself?" placeholder="type your thoughts here" defaultValue={hc.negativeThoughts} handleChangeCallback={handleChangeNegativeThoughts} />) }

        { showShamePower && ( <div className="intro ion-padding emotional-granularity-bg">
            <h2>Shame has a lot of power when you don’t confront it.</h2>
            <p>Now that you know what the thoughts are, ask yourself two things:</p>
            <p>1. Where did these bullshit thoughts come from?</p>
            <p>2. How might these shame thoughts impact you during this upcoming conversation?</p>
          </div>) }

        { showThoughtOrigins && (<Prompt title="" description="Where do these bullshit thoughts come from?" placeholder="type here" defaultValue={hc.thoughtOrigins} handleChangeCallback={handleChangeThoughtOrigins} />) }

        { showThoughtImpacts && (<Prompt title="" description="How might these thoughts impact you during this hard conversation?" placeholder="type here" defaultValue={hc.thoughtImpacts} handleChangeCallback={handleChangeThoughtImpacts} />) }

        { showSelfCompassion && (<Prompt title="Shame loses it’s power when confronted with self-compassion" description="Write something kind to yourself to combat your shame story." placeholder="type here" defaultValue={hc.selfCompassion} handleChangeCallback={handleChangeSelfCompassion} />) }

        { showConclusion && (<div className="conclusion vulnerability-bg">
          <div className="ion-padding section">
            <h2>Confront your shame and tell it why the story it’s telling you is wrong.</h2>
            <div className="activity-outcomes">
              <p className={"boundaries"}>
                  {hc.selfCompassion}
              </p>
              <a href="#" className="edit-activity" onClick={(e) => {navigateInPage('negativeThoughts', e)}}>Edit</a>
            </div>
            <p>This self-compassionate statement is what you will repeat to yourself if you feel your shame story creeping in during the conversation so you can stay emotionally regulated and focused. </p>
          </div>
          <div className="ion-padding f-off section">
            <h1 className="xxl">Fuck off shame!</h1>
          </div>
        </div>) }
      </IonContent>
      { (pageNavNext || pageNavPrev) &&
        (
          <IonFooter className="page-navigation-wrapper">
            <div className="page-navigation">
              { pageNavPrev && (<a href="#" className="prev" onClick={(e) => {navigateInPage(pageNavPrev, e)}}><img alt="previous icon" src="/assets/page-nav-arrow.svg" /></a> ) }
              { !showShamePower && <IonRouterLink className={"button secondary helpme"} onClick={() => setShowModal(true)}>Help me!</IonRouterLink> }
              <IonModal isOpen={showModal} cssClass='modal-padding'>
                  <IonContent className="ion-padding"> 
                    { showNegativeThoughts && (<div>
                      <p>Shame, weirdly, is a self-protective emotion. If you tell yourself you suck first, it won’t hurt as much. What do you say to yourself when you feel vulnerable?</p>
                      <h2 className="emotional-granularity">I am not smart enough to do this</h2>
                      <h2 className="shame">I’m not an important part of this group</h2>
                      <h2 className="anxiety">I’m not good at anything</h2>
                    </div>)}
                    
                    { showThoughtOrigins && (<div>
                      <p>We learn our shame, it doesn’t just come out of nowhere. It depends on context, culture, and how you are socialized.</p>
                      <h2 className="emotional-granularity">I didn’t like school as a kid, and was labeled ‘the dumb kid’</h2>
                      <h2 className="shame">Everyone else seems to make more important contributions</h2>
                      <h2 className="anxiety">I grew up being criticized by my family a lot</h2>
                    </div>)}

                    { showThoughtImpacts && (<div>
                      <p>Hard conversations are super vulnerable. Shame doesn’t like vulnerability and directly impacts your behavior and how you participate in spaces.</p>
                      <h2 className="emotional-granularity">If I feel dumb I  get defensive and cannot participate</h2>
                      <h2 className="shame">I don’t show up or take my share of responsibility</h2>
                      <h2 className="anxiety">I don’t share my ideas and thoughts</h2>
                    </div>)}

                    { showSelfCompassion && (<div>
                      <h2 className="emotional-granularity">This feeling sucks. This is hard and I feel vulnerable, but I am not worthless</h2>
                      <h2 className="shame">It is hard to stand up for yourself, but you get to matter</h2>
                      <h2 className="anxiety">I always worry I am too much, but I am allowed to have needs</h2>
                    </div>)}
                      
                    <IonRouterLink className="button close" onClick={() => setShowModal(false)}><IonIcon icon={close}/></IonRouterLink>
                  </IonContent>
                </IonModal> 
              { pageNavNext && (<a href="#" className="next" onClick={(e) => {navigateInPage(pageNavNext, e)}}><img alt="next icon" src="/assets/page-nav-arrow.svg" /></a> ) }
            </div>
          </IonFooter>
        )
      }
    </IonPage>
  );
};

export default BattleShame;