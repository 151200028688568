import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { IonContent, IonGrid, IonIcon, IonRow, IonCol, IonPage, IonRouterLink, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle } from '@ionic/react';
import { AppContext } from '../State';
import { v4 as uuidv4 } from 'uuid';
import { chevronForward, add, chevronBack } from 'ionicons/icons';

const HardConversations: React.FC = () => {
  const {state, dispatch} = useContext(AppContext);  

  const [newHCId, setNewHCId] = useState(uuidv4());

  const createHardConversation = () => {
    dispatch({
      type: 'addHardConversation',
      id: newHCId
    });
  }

  useEffect(() => { 
    setNewHCId(uuidv4());
  }, [state.hardConversations.length]);

  const existingHC = (()=>{
    return !!state.hardConversations.find((data:any) => { return data.active === true;});
  })();

  if (!existingHC) {
    return (<Redirect to='/guides/hard-conversations/intro' />)
  } else {
    return (
      <IonPage className="home-bg">
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton routerLink="/home" routerDirection="back" className="fake-back-button"><IonIcon icon={chevronBack} /> Home</IonButton>
          </IonButtons>
          <IonTitle>Wild Feelings</IonTitle>
        </IonToolbar>
      </IonHeader>
        <IonContent className="ion-padding">
          <div>
            <div>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonRouterLink className={"button w-o-b home-create"}  onClick={createHardConversation} routerLink={"/guides/hard-conversations/"+newHCId+"/create"} routerDirection="forward"><IonIcon icon={add} /> New Conversation</IonRouterLink>
                    {state.hardConversations.filter(
                      (hc:any) => {
                        if (hc.active) {
                          return hc;
                        }
                      }
                    ).reverse().map((hc : any) =>
                      <div key={hc.id}>
                        <h1><IonRouterLink routerLink={"/guides/hard-conversations/"+hc.id} routerDirection="forward" className="hard-conversation-link">{hc.name} <IonIcon icon={chevronForward} /></IonRouterLink></h1>
                      </div>
                    )}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>  
        </IonContent>
      </IonPage>
    );
  }
}

export default HardConversations;