import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Navicon from './components/Navicon';
import Home from './pages/Home';
import HardConversations from './pages/HardConversations';
import HardConversation from './pages/guides/hardConversations/HardConversation';
import CreateConversation from './pages/guides/hardConversations/CreateConversation';
import HardConversationsIntro from './pages/guides/hardConversations/intro/Intro';
import GuideHardConversationsFeelingsList from './pages/guides/hardConversations/FeelingsList';
import GuideHardConversationsPrepareList from './pages/guides/hardConversations/PrepareList';
import GuideHardConversationsReboundList from './pages/guides/hardConversations/ReboundList';
import Shame from './pages/guides/hardConversations/feelings/Shame';
import Anger from './pages/guides/hardConversations/feelings/Anger';
import Anxiety from './pages/guides/hardConversations/feelings/Anxiety';
import MakeAList from './pages/guides/hardConversations/prepare/MakeAList';
import Breathe from './pages/guides/hardConversations/prepare/Breathe';
import DefineBoundaries from './pages/guides/hardConversations/prepare/DefineBoundaries';
import DefineFeelings from './pages/guides/hardConversations/rebound/DefineFeelings';
import AffirmYourWorth from './pages/guides/hardConversations/rebound/AffirmYourWorth';
import BattleShame from './pages/guides/hardConversations/prepare/BattleShame';

import { createBrowserHistory } from 'history';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';


import './theme/app.scss';



const history = createBrowserHistory();

const App: React.FC = () => (
  <IonApp>
      <IonReactRouter history={history}>
        <IonRouterOutlet animated={false} >
          <Route path="/home" component={Home} />
          <Route path="/hard-conversations" component={HardConversations} exact={true} />
          <Route path="/guides/hard-conversations/:id" component={HardConversation} exact={true} />
          <Route path="/guides/hard-conversations/intro" component={HardConversationsIntro} exact={true} />
          <Route path="/guides/hard-conversations/:id/create" component={CreateConversation} exact={true} />
          <Route path="/guides/hard-conversations/:id/feelings" component={GuideHardConversationsFeelingsList} exact={true} />
          <Route path="/guides/hard-conversations/:id/prepare" component={GuideHardConversationsPrepareList} exact={true} />
          <Route path="/guides/hard-conversations/:id/rebound" component={GuideHardConversationsReboundList} exact={true} />
          <Route path="/guides/hard-conversations/:id/feelings/shame" component={Shame} exact={true} />
          <Route path="/guides/hard-conversations/:id/feelings/anger" component={Anger} exact={true} />
          <Route path="/guides/hard-conversations/:id/feelings/anxiety" component={Anxiety} exact={true} />
          <Route path="/guides/hard-conversations/:id/prepare/make-a-list" component={MakeAList} exact={true} />
          <Route path="/guides/hard-conversations/:id/prepare/breathe" component={Breathe} exact={true} />
          <Route path="/guides/hard-conversations/:id/prepare/define-boundaries" component={DefineBoundaries} exact={true} />
          <Route path="/guides/hard-conversations/:id/rebound/define-your-feelings" component={DefineFeelings} exact={true} />
          <Route path="/guides/hard-conversations/:id/rebound/affirm-your-worth" component={AffirmYourWorth} exact={true} />
          <Route path="/guides/hard-conversations/:id/prepare/battle-shame" component={BattleShame} exact={true} />
          <Route path="/feelings" component={GuideHardConversationsFeelingsList} exact={true} />
          <Route path="/feelings/shame" component={Shame} exact={true} />
          <Route path="/feelings/anger" component={Anger} exact={true} />
          <Route path="/feelings/anxiety" component={Anxiety} exact={true} />
          <Route path="/" render={() => <Redirect to="/home" />} exact={true} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
);

export default App;
