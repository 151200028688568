import React, { useState, useEffect, useContext } from 'react';
import { IonContent, IonRouterLink, IonButtons, IonFooter, IonBackButton, IonHeader, IonPage, IonToolbar, IonModal, IonIcon } from '@ionic/react';
import TextareaAutosize from 'react-textarea-autosize';
import KeyboardUtils from '../../../../utils/keyboardUtil';
import {close} from 'ionicons/icons';
import { AppContext } from '../../../../State';
import { useParams } from 'react-router';
import Analytics from '../../../../services/Analytics'

const DefineStrategies: React.FC = () => {
  const {state, dispatch} = useContext(AppContext);
  
  let { id } = useParams();
  const hc = ((id:string)=>{
    return state.hardConversations.find((data:any) => { return data.id===id});
  })(id);
  
  const updateBoundaryStrategies = function(boundaryStrategies: any) {
    dispatch({
      type: 'setBoundaryStrategies',
      payload: {
        id: hc.id,
        boundaryStrategies: boundaryStrategies
      }
    });
  }

  const handleChange = function(evt: any) {
    if (evt !== null && evt.target !==  null) {
      updateBoundaryStrategies(evt.target.value);
    }
  };

  return(
    <div className={"emotional-hijacking-bg ion-padding define-strategies"}>
      <p>What regulatory strategies can you use in the moment if you feel your boundaries being pushed or you’re getting emotionally activated?</p>  
      <form>
      <TextareaAutosize 
        
        defaultValue={hc.boundaryStrategies}
        onChange={handleChange}
        placeholder="Type your strategies here "
        className="magic-text-area"
        no-blur="true" />
      </form>
    </div>
  )
}

const DefineBoundaries: React.FC = () => {
  const {state, dispatch} = useContext(AppContext);

  let { id } = useParams();
  const hc = ((id:string)=>{
    return state.hardConversations.find((data:any) => { return data.id===id});
  })(id);
  
  const updateBoundaries = function(boundaries: any) {
    dispatch({
      type: 'setBoundaries',
      payload: {
        id: hc.id,
        boundaries: boundaries
      }
    });
  }

  const handleChange = function(evt: any) {
    if (evt !== null && evt.target !==  null) {
      updateBoundaries(evt.target.value);
    }
  };

  return(
    <div className={"neuroplasticity-bg ion-padding define-boundaries"}>
      <p>What boundaries do you need to maintain to feel supported and safe during a hard conversation?</p>  
      <form>
      <TextareaAutosize 
        
        defaultValue={hc.boundaries}
        onChange={handleChange}
        placeholder="Type your boundaries here "
        className="magic-text-area"
        no-blur="true" />
      </form>
    </div>
  )
}

const Boundaries: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [showDefineBoundaries, setShowDefineBoundaries] = useState(false);
  const [showDefineStrategies, setShowDefineStrategies] = useState(false);
  const [showConclusion, setShowConclusion] = useState(false);
  const [pageNavPrev, setPageNavPrev] = useState('');
  const [pageNavNext, setPageNavNext] = useState('');
  const {state, dispatch} = useContext(AppContext);
  
  let { id } = useParams();
  const hc = ((id:string)=>{
    return state.hardConversations.find((data:any) => { return data.id===id});
  })(id);

  KeyboardUtils.showAccessoryBar();

  const navigateInPage = function(navigateTo : string, event? : any,) {
    Analytics.logEvent({
      name: "screen_view",
      params : {
        screen_name: "Define Boundaries - " + navigateTo
      }
    });

    if (event !== undefined) {
      event.preventDefault();
    }
    switch(navigateTo) {
      case 'intro':
        setShowIntro(true);
        setShowDefineBoundaries(false);
        setShowDefineStrategies(false);
        setShowConclusion(false);
        setPageNavNext('');
        setPageNavPrev('');
        break;
      case 'startDefineBoundaries':
        setShowIntro(false);
        setShowDefineBoundaries(true);
        setShowDefineStrategies(false);
        setShowConclusion(false);
        setPageNavNext('defineStrategies');
        setPageNavPrev('intro');
        break;
      case 'defineStrategies':
        setShowDefineBoundaries(false);
        setShowDefineStrategies(true);
        setShowConclusion(false);
        setShowIntro(false);
        setPageNavNext('conclusion');
        setPageNavPrev('startDefineBoundaries');
        break;
      case 'conclusion':
        setShowDefineStrategies(false);
        setShowDefineBoundaries(false);
        setShowConclusion(true);
        setShowIntro(false);
        setPageNavNext('');
        setPageNavPrev('');
        break;
    }
  }

  useEffect(() => { 
    if (hc.boundariesComplete) {
      navigateInPage('conclusion');
    }
  }, []);

  useEffect(() => { 
    console.log("show conclusion?")
    if (showConclusion === true) {
      dispatch({
        type: 'setBoundariesComplete',
        payload: {
          id: hc.id,
          boundariesComplete: true
        }
      });
    }
  }, [showConclusion]);


  return (
    <IonPage className={"boundaries emotional-granularity-bg"}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        { showIntro && (<div><div className="intro ion-padding section">
          <h1>Think about Boundaries</h1>
          <p>Boundaries are the act of making clear what is ok with us, what is not ok with us, and clarifying why.</p>
          <p>When your boundaries get violated it causes an automatic, and sometimes unconscious, emotional reaction.</p>
        </div>
        <div className="neuroplasticity-bg ion-padding section">
          <h2>Intentionally thinking through boundaries before a challening interaction helps you understand and prepare for what might activate you in the moment.</h2>
        </div>
        <div className="ion-padding section">
          <p>Getting clear about your boundaries helps you feel less anxious, more in control, and able to depersonalize.</p>
          <p>It also helps you plan how to regulate during the conversation so you can avoid getting overtaken by your emotions.</p>
          <IonRouterLink className="button" onClick={(e) => {navigateInPage('startDefineBoundaries', e)}}>Get Started</IonRouterLink>
        </div></div>)}

        { showDefineBoundaries && (<DefineBoundaries />) }
        { showDefineStrategies && (<DefineStrategies />) }

        { showConclusion && (<div className="conclusion">
          <div className="neuroplasticity-bg ion-padding section">
            <h2>Keep your boundaries in mind and at the forefront as you organize the terms of the conversation and your relationship with the person.</h2>
            <div className="activity-outcomes">
              <p className={"boundaries"}>
                {hc.boundaries}
              </p>
              <a href="#" className="edit-activity" onClick={(e) => {navigateInPage('startDefineBoundaries', e)}}>Edit</a>
            </div>
          </div>
          <div className="emotional-hijacking-bg ion-padding section">
            <h2>During the conversation, try to catch yourself when you might become activated and use your strategies before you get too deep into your own emotional reaction.</h2>
            <div className="activity-outcomes">
              <p className={"strategies"}>
                {hc.boundaryStrategies}
              </p>
              <a href="#" className="edit-activity" onClick={(e) => {navigateInPage('defineStrategies', e)}}>Edit</a>
            </div>
          </div>
          <div className="ion-padding section">
            <h1 className="xl">You Matter. <br /><br />You don’t have to put up with shit you don’t like</h1>
          </div>
        </div>) }
      </IonContent>
      { (pageNavNext || pageNavPrev) &&
        (
          <IonFooter className="page-navigation-wrapper">
            <div className="page-navigation">
              { pageNavPrev && (<a href="#" className="prev" onClick={(e) => {navigateInPage(pageNavPrev, e)}}><img alt="previous icon" src="/assets/page-nav-arrow.svg" /></a> ) }
              <IonRouterLink className={"button secondary helpme"} onClick={() => setShowModal(true)}>Help me!</IonRouterLink>
              <IonModal isOpen={showModal} cssClass='modal-padding'>
                  <IonContent className="ion-padding"> 
                    { showDefineBoundaries && (<div>
                      <h2 className="emotional-granularity">how to think about it</h2>
                      <p>If someone wants to meet alone and that doesn’t work for you, are you going to insist someone from HR or another position join the meeting?</p>
                      <p>If you are delivering hard information or feedback how are you going to stay on task and maintain your boundaries if the other person gets emotional?</p>
                      <p>If someone raises their voice, how could you share that that makes you uncomfortable?</p>
                      <p>If you feel this meeting is connected to conscious or unconscious bias, how can you advocate for yourself?</p>
                      <p>If the other person starts brining up other topics or incidents that are not connected to the purpose of this conversation, how can you react?</p>
                      <p>If you feel this conversation is no longer productive or becoming an attack, how are you going to protect yourself and exit?</p>
                    </div>)}
                    
                    { showDefineStrategies && (<div>
                      <p>A regulatory strategy is a lorium ipsum blah blah blah</p>
                      <h2 className="emotional-granularity">take a 3 deep breaths through your nose</h2>
                      <h2 className="shame">have a code word to remind yourself to  stay firm</h2>
                      <h2 className="anxiety">remind yourself this is a negotiation not a battle</h2>
                      <h2 className="anger">call for a quick break in the convo</h2>
                      <h2 className="neuroplascticity">Have a short phrase you can repeat to affirm your worth</h2>
                      
                    </div>)}
                      
                    <IonRouterLink className="button close" onClick={() => setShowModal(false)}><IonIcon icon={close}/></IonRouterLink>
                  </IonContent>
                </IonModal> 
              { pageNavNext && (<a href="#" className="next" onClick={(e) => {navigateInPage(pageNavNext, e)}}><img alt="next icon" src="/assets/page-nav-arrow.svg" /></a> ) }
            </div>
          </IonFooter>
        )
      }
    </IonPage>
  );
};

export default Boundaries;