import React, { createContext, useReducer, useEffect, useState } from "react";
import {get, set} from './services/storage.ts';

let AppContext = createContext();

const initialState = {
  hardConversations: []
  /*
  [{
    id: 0,
    worries: "",
    emotions: "",
    feelingsList: [
      {
        id: 0,
        name: ""
      }
    ],
    details: ""
  }]  
  */
}

let reducer = (state, action) => {
  const initialHcState = {
    id: action.id,
    name: "",
    worries: "",
    emotions: "",
    affirmationStatement: "",
    boundaries: "",
    boundaryStrategies: "",
    boundariesComplete: false,
    negativeThoughts: "",
    thoughtOrigins: "",
    thoughtImpacts: "",
    selfCompassion: "",
    battleShameComplete: false,
    listComplete: false,
    list: [
      {
        id: 0,
        name: ""
      }
    ],
    feelingsListComplete: false,
    feelingsList: [
      {
        id: 0,
        name: ""
      }
    ],
    details: "",
    active: false
  };

  switch(action.type) {
    case "addHardConversation": {
      return {
        hardConversations: [
          ...state.hardConversations,
          initialHcState
        ]
      }
    }
    case "setHardConversations": {
      let updatedHc = action.hardConversations.map(item => {
        return {
          ...initialHcState,
          ...item
        }
      });
      return { 
        ...state, 
        hardConversations: updatedHc 
      }
    }
    case "setWorries": {
      if (action.payload.id) {

        let hardConversations = state.hardConversations.map(hc => {
          if (hc.id === action.payload.id) {
            return Object.assign({}, hc, {worries: action.payload.worries});
          } 
          return hc      
        })

        return { 
          ...state,
          hardConversations
        }
      }
    }
    case "setNegativeThoughts": {
      if (action.payload.id) {

        let hardConversations = state.hardConversations.map(hc => {
          if (hc.id === action.payload.id) {
            return Object.assign({}, hc, {negativeThoughts: action.payload.negativeThoughts});
          } 
          return hc      
        })

        return { 
          ...state,
          hardConversations
        }
      }
    }
    case "setThoughtOrigins": {
      if (action.payload.id) {

        let hardConversations = state.hardConversations.map(hc => {
          if (hc.id === action.payload.id) {
            return Object.assign({}, hc, {thoughtOrigins: action.payload.thoughtOrigins});
          } 
          return hc      
        })

        return { 
          ...state,
          hardConversations
        }
      }
    }
    case "setThoughtImpacts": {
      if (action.payload.id) {

        let hardConversations = state.hardConversations.map(hc => {
          if (hc.id === action.payload.id) {
            return Object.assign({}, hc, {thoughtImpacts: action.payload.thoughtImpacts});
          } 
          return hc      
        })

        return { 
          ...state,
          hardConversations
        }
      }
    }
    case "setSelfCompassion": {
      if (action.payload.id) {

        let hardConversations = state.hardConversations.map(hc => {
          if (hc.id === action.payload.id) {
            return Object.assign({}, hc, {selfCompassion: action.payload.selfCompassion});
          } 
          return hc      
        })

        return { 
          ...state,
          hardConversations
        }
      }
    }
    case "setBattleShameComplete": {
      if (action.payload.id) {

        let hardConversations = state.hardConversations.map(hc => {
          if (hc.id === action.payload.id) {
            return Object.assign({}, hc, {battleShameComplete: action.payload.battleShameComplete});
          } 
          return hc      
        })

        return { 
          ...state,
          hardConversations
        }
      }
    }
    case "setAffirmationStatement": {
      if (action.payload.id) {

        let hardConversations = state.hardConversations.map(hc => {
          if (hc.id === action.payload.id) {
            return Object.assign({}, hc, {affirmationStatement: action.payload.affirmationStatement});
          } 
          return hc      
        })

        return { 
          ...state,
          hardConversations
        }
      }
    }
    case "setBoundaries": {
      if (action.payload.id) {

        let hardConversations = state.hardConversations.map(hc => {
          if (hc.id === action.payload.id) {
            return Object.assign({}, hc, {boundaries: action.payload.boundaries});
          } 
          return hc      
        })

        return { 
          ...state,
          hardConversations
        }
      }
    }
    case "setBoundaryStrategies": {
      if (action.payload.id) {

        let hardConversations = state.hardConversations.map(hc => {
          if (hc.id === action.payload.id) {
            return Object.assign({}, hc, {boundaryStrategies: action.payload.boundaryStrategies});
          } 
          return hc      
        })

        return { 
          ...state,
          hardConversations
        }
      }
    }
    case "setBoundariesComplete": {
      if (action.payload.id) {

        let hardConversations = state.hardConversations.map(hc => {
          if (hc.id === action.payload.id) {
            return Object.assign({}, hc, {boundariesComplete: action.payload.boundariesComplete});
          } 
          return hc      
        })

        return { 
          ...state,
          hardConversations
        }
      }
    }
    case "setFeelingsListComplete": {
      if (action.payload.id) {

        let hardConversations = state.hardConversations.map(hc => {
          if (hc.id === action.payload.id) {
            return Object.assign({}, hc, {feelingsListComplete: action.payload.feelingsListComplete});
          } 
          return hc      
        })

        return { 
          ...state,
          hardConversations
        }
      }
    }
    case "setFeelingsList": {
      if (action.payload.id) {

        let hardConversations = state.hardConversations.map(hc => {
          if (hc.id === action.payload.id) {
            return Object.assign({}, hc, {feelingsList: action.payload.feelingsList});
          } 
          return hc      
        })

        return { 
          ...state,
          hardConversations
        }
      }
    }
    case "setListComplete": {
      if (action.payload.id) {

        let hardConversations = state.hardConversations.map(hc => {
          if (hc.id === action.payload.id) {
            return Object.assign({}, hc, {listComplete: action.payload.listComplete});
          } 
          return hc      
        })

        return { 
          ...state,
          hardConversations
        }
      }
    }
    case "setList": {
      if (action.payload.id) {

        let hardConversations = state.hardConversations.map(hc => {
          if (hc.id === action.payload.id) {
            return Object.assign({}, hc, {list: action.payload.list});
          } 
          return hc      
        })

        return { 
          ...state,
          hardConversations
        }
      }
    }
    case "setDetails": {
      if (action.payload.id) {

        let hardConversations = state.hardConversations.map(hc => {
          if (hc.id === action.payload.id) {
            return Object.assign({}, hc, {details: action.payload.details});
          } 
          return hc      
        })

        return { 
          ...state,
          hardConversations
        }
      }
    }
    case "setName": {
      if (action.payload.id && action.payload.name.length > 0) {

        let hardConversations = state.hardConversations.map(hc => {
          if (hc.id === action.payload.id) {
            return Object.assign({}, hc, {name: action.payload.name, active: true});
          } 
          return hc      
        })

        return { 
          ...state,
          hardConversations
        }
      }
    }
  }
  return state;
};

const logger = (reducer) => {
  const reducerWithLogger = (state, action) => {
    console.log("%cPrevious State:", "color: #9E9E9E; font-weight: 700;", state);
    console.log("%cAction:", "color: #00A7F7; font-weight: 700;", action);
    console.log("%cNext State:", "color: #47B04B; font-weight: 700;", reducer(state,action));
    return reducer(state, action);
  };
  return reducerWithLogger;
}

const loggerReducer = logger(reducer);


function AppContextProvider(props) {
  const [persistenceLoaded, setPersistenceLoaded] = useState(false);

  useEffect(() => { 
    async function getData() {
      await get('wfState').then(function(data) {
        if (data.hardConversations) {
          dispatch({
            type: 'setHardConversations',
            hardConversations: data.hardConversations
          });
        }
        setPersistenceLoaded(true);
      });
    }
    setTimeout(function() {
      // This stupid component that manages auto height 
      // doesn't like re-rendering so quickly.
      // So I have to use a stupid timeout here
      getData();
    }, 500)
  }, []);

  let [state, dispatch] = useReducer(loggerReducer, initialState);

  useEffect(() => {
    // Persist any state we want to
    if (persistenceLoaded) {
      set('wfState', {
        hardConversations: state.hardConversations
      });
    }
  }, [state]);

  let value = { state, dispatch };



  
  return (
    <div>
    { persistenceLoaded && <AppContext.Provider value={value}>{props.children}</AppContext.Provider> }
    </div>
  );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };