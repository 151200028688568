import React, { useState, useContext, useRef, useEffect } from 'react';
import { IonContent, IonFooter, IonRouterLink, IonButtons, IonBackButton, IonIcon, IonHeader, IonPage, IonToolbar, IonModal } from '@ionic/react';
import KeyboardUtils from '../../../../utils/keyboardUtil';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import ArrayMove from 'array-move';
import { AppContext } from '../../../../State';
import { useParams } from 'react-router';
import {close} from 'ionicons/icons';
import Analytics from '../../../../services/Analytics'
import { v4 as uuidv4 } from 'uuid';

const ClassNames = require('classnames');

const SortableItem = SortableElement(({value} : any) => <div className="feelings-list-item">
  <div className="feeling-name">
    {value.name}
  </div>
  <span className={"sort-item"}><img alt="sorting icon" src="/assets/list-sort-item.svg" / ></span>
</div>);

const SortableList = SortableContainer(({items} : any) => {
  return(
    <div className={"feelings-list sort"}>
      {items.map((value:any, index:any) => (
        <SortableItem key={`item-${value.name}`} index={index} value={value} />
      ))}
    </div>
  )
});

type FeelingsProps = {
  mode? : string
}

interface FeelingsList {
  id: number,
  name: string
}

interface state {
  feelingsList: Array<Object>
}
 
const FeelingsList: React.FC<FeelingsProps> = ({mode}) => {
  const {state, dispatch} = useContext(AppContext);
  
  let { id } = useParams();
  const hc = ((id:string)=>{
    return state.hardConversations.find((data:any) => { return data.id===id});
  })(id);


  const useFocus = () => {
    const htmlElRef: any = useRef(null)
    
    const setFocus = () => {
      if (htmlElRef.current != null) {
        htmlElRef.current && htmlElRef.current.focus()
      }
    }
    return [ htmlElRef, setFocus ] 
  }
  const [inputRef, setInputFocus] = useFocus();


  const updateFeelingsList = function(feelingsList: Array<FeelingsList>) {
    dispatch({
      type: 'setFeelingsList',
      payload: {
        id: hc.id,
        feelingsList: feelingsList
      }
    });
  }
  
  const addItem = () => {
    updateFeelingsList([
      ...hc.feelingsList,
      {
        id: hc.feelingsList.length,
        key: uuidv4(),
        name: ""
      }
    ]);
  };

  const updateItem = (itemId : any, itemValue : string) => {
    let localItems = hc.feelingsList;
    localItems[itemId] = {
      id: itemId,
      key: localItems[itemId].key ? localItems[itemId].key : uuidv4(),
      name: itemValue
    };

    updateFeelingsList(localItems)
  }

  const deleteItem = (e: React.MouseEvent<HTMLAnchorElement>, itemId : any) => {
    e.preventDefault();
    let localItems = hc.feelingsList;
    localItems.splice(itemId, 1);
    let newItems = localItems.map(function(currentValue : any, index: Number){
      return {
        id: index,
        key: currentValue.key,
        name: currentValue.name
      }
    });
    updateFeelingsList(newItems);
  }

  const sortItem = (itemId : any) => {
    console.log(itemId);
  }

  type SortEndProps = {
    oldIndex: any,
    newIndex: any
  }

  const onSortEnd = ({oldIndex, newIndex} : SortEndProps) => {
    updateFeelingsList(ArrayMove(hc.feelingsList, oldIndex, newIndex));
  };

  const containerClasses = ClassNames(
    'ion-padding',
    'make-a-list',
    {
      'emotional-granularity-bg': mode === 'sort',
      'shame-bg': mode === 'make'
    }
  )

  return(
    <div className={containerClasses}>
      { mode === 'make' && (
        <div>
          <p>List everything you are feeling right now.</p>  
          <form className="feelings-list">
            {hc.feelingsList.map((item:any) => (
              <div className="feelings-list-item"  key={item.key}>
                <label>
                  <input
                    className="feeling-name"
                    name="item"
                    type="text"
                    defaultValue={item.name}
                    
                    placeholder="Type a feeling"
                    onChange={e => updateItem(item.id, e.target.value)}
                  />
                </label>
                <a href="#" className={"remove-item"} onClick={e => deleteItem(e, item.id)}><img alt="removing icon" src="/assets/list-remove-item.svg" / ></a>
              </div>
            ))}
          </form>
          <button className={"add-item"} onClick={addItem}><img alt="adding icon" src="/assets/list-add-item.svg" / ></button>
        </div>
      )}
      { mode === 'sort' && (
        <div>
          <p>Awesome... now let’s narrow that down to your top 3</p>
          <SortableList items={hc.feelingsList} onSortEnd={onSortEnd} axis="y" lockAxis="y" lockToContainerEdges="true" />
        </div>
      )}
    </div>
    
  )
}


const DefineFeelings: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [showMakeList, setShowMakeList] = useState(false);
  const [showSortList, setShowSortList] = useState(false);
  const [showConclusion, setShowConclusion] = useState(false);
  const [pageNavPrev, setPageNavPrev] = useState('');
  const [pageNavNext, setPageNavNext] = useState('');
  const {state, dispatch} = useContext(AppContext);

  let { id } = useParams();
  const hc = ((id:string)=>{
    return state.hardConversations.find((data:any) => { return data.id===id});
  })(id);

  KeyboardUtils.showAccessoryBar();

  const updateFeelingsList = function(feelingsList: Array<FeelingsList>) {
    dispatch({
      type: 'setFeelingsList',
      payload: {
        id: hc.id,
        feelingsList: feelingsList
      }
    });
  }

  const loadUnstuckOption = function(evt: any) {
    if (evt !== null && evt.target !==  null) {

      updateFeelingsList([
        ...hc.feelingsList,
        {
          id: hc.feelingsList.length,
          name: evt.target.innerHTML
        }
      ]);
    }
    setShowModal(false);
  };

  const navigateInPage = function(navigateTo : string, event? : any,) {
    Analytics.logEvent({
      name: "screen_view",
      params : {
        screen_name: "Define Your Feelings - " + navigateTo
      }
    });
    if (event !== undefined) {
      event.preventDefault();
    }
    switch(navigateTo) {
      case 'intro':
        setShowIntro(true);
        setShowMakeList(false);
        setShowSortList(false);
        setShowConclusion(false);
        setPageNavNext('');
        setPageNavPrev('');
        break;
      case 'startDefineFeelings':
        setShowIntro(false);
        setShowMakeList(true);
        setShowSortList(false);
        setShowConclusion(false);
        setPageNavNext('sortFeelings');
        setPageNavPrev('intro');
        break;
      case 'sortFeelings':
        setShowMakeList(false);
        setShowSortList(true);
        setShowConclusion(false);
        setShowIntro(false);
        setPageNavNext('conclusion');
        setPageNavPrev('startDefineFeelings');
        break;
      case 'conclusion':
        setShowSortList(false);
        setShowMakeList(false);
        setShowConclusion(true);
        setShowIntro(false);
        setPageNavNext('');
        setPageNavPrev('');
        break;
    }
  }


  useEffect(() => { 
    if (hc.listComplete) {
      navigateInPage('conclusion');
    }
  }, []);

  useEffect(() => { 
    if (showConclusion === true) {
      dispatch({
        type: 'setFeelingsListComplete',
        payload: {
          id: hc.id,
          feelingsListComplete: true
        }
      });
    }
    
  }, [showConclusion]);


  const possibleFeelings = ["Anger","Shame","Anxiety","Guilt","Helplessness","Curious","Hesitant","Scared","Exposed","Hopeless","Threatened","Lost","Shocked","Testy","Used","Spiteful","Hopeful","Excited","Grateful","Optimistic","Tender","Thankful","Offended","Ornery","Rebellious","Accused","Bitter","Aggravated","Hostile","Inept","Inferior","Insignificant","Stupid","Squashed","Ignored","Guilty","Incredulous","Exhilarated","Insecure","Sheepish","Resentful","Satisfied","Uplifted"];

  return (
    <IonPage className={'anxiety-bg define-your-feelings'}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
      { showIntro && (<div className="intro ion-padding bg-green-blob align-bottom">
          <div className="scroll-container">
            <h1 className="xl">Take a breath and try and get curious about how YOU feel.</h1>
            <IonRouterLink className="button" onClick={(e) => {navigateInPage('startDefineFeelings', e)}}>Get Started</IonRouterLink>
          </div>
        </div>)}
      { showMakeList && <FeelingsList mode="make" />}
      { showSortList && <FeelingsList mode="sort" />}
      { showConclusion && (
        <div className="conclusion">
          <div className="shame-bg ion-padding section">
            <h2>These are the feelings we need to focus on.</h2>
            <div className="activity-outcomes">
              <ul className={"list"}>
                {hc.feelingsList.slice(0, 3).map((item:any) => (
                  <li>{item.name}</li>
                ))}
              </ul>
              <a href="#" className="edit-activity" onClick={(e) => {navigateInPage('startDefineFeelings', e)}}>Edit</a>
            </div>
          </div>
          <div className="emotional-granularity-bg ion-padding section">
            <h2>Idenitfying our emotions is the first step towards regulating them.</h2>
            <p>Getting specific about which emotions feel the biggest is super important.</p>
            <p>Emotion regulation is not about immediately "getting rid of" feelings, it is about paying attention.</p>
            <p>Try not to judge your emotions, or immediately run away from them, even though they might be hard to feel.  You can’t heal what you can’t feel. </p>
          </div>
          <div className="shame-bg ion-padding section">
            <h2>Strategies to regulate the core emotions you identified:</h2>
            <div className={"shapeContainer definefeelings-shapes"}>
                <div className={"shape shape-definefeelings-1"}>
                  Mindful Breathing through your nose 
                </div>
                <div className={"shape shape-definefeelings-2"}>
                  Get physical - go for a walk, pace, talk outloud to yourself, write down notes, plan to workout later
                </div>
                <div className={"shape shape-definefeelings-3"}>
                    Think about your thinking - what activated each emotion? Is Shame enouraging me to feel this way?  What is a nice thing you can do for yourself later today or tomorrow?
                </div>
                <div className={"shape shape-definefeelings-4"}>
                  Positive Reappraisal - reframe the aspects of the conversation that caused you to feel these strong emotions in a positive light. Research shows this strategy deactivates your stress response and lessens the intensity of emotions. For example, your boss was being a total asshole. Well, maybe they got some really bad news this morning and that antagonism had nothing to do with you. 
                </div>
            </div>
          </div>
          
        </div>
      )}
        
      </IonContent>
      { (pageNavNext || pageNavPrev) &&
        (
          <IonFooter className="page-navigation-wrapper">
            <div className="page-navigation">
              { pageNavPrev && (<a href="#" className="prev" onClick={(e) => {navigateInPage(pageNavPrev, e)}}><img alt="previous icon" src="/assets/page-nav-arrow.svg" /></a> ) }
              <IonRouterLink className={"button secondary helpme"} onClick={() => setShowModal(true)}>Help me!</IonRouterLink>
              <IonModal isOpen={showModal} cssClass='modal-padding'>
                  <IonContent className="ion-padding"> 
                    { showMakeList && (<ul className="unstuck-options">
                        {possibleFeelings.map((feeling:any) => (
                          <li key={feeling} onClick={loadUnstuckOption}>{feeling}</li>
                        ))}
                    </ul>)}
                    
                    { showSortList && (<p>Really try to focus on the top feelings you're feeling. These will help you understand and uncover why you're feeling the way you're feeling.</p>)}
                      
                    <IonRouterLink className="button close" onClick={() => setShowModal(false)}><IonIcon icon={close}/></IonRouterLink>
                  </IonContent>
                </IonModal> 
              { pageNavNext && (<a href="#" className="next" onClick={(e) => {navigateInPage(pageNavNext, e)}}><img alt="next icon" src="/assets/page-nav-arrow.svg" /></a> ) }
            </div>
          </IonFooter>
        )
      }
    </IonPage>
  );
};

export default DefineFeelings;