import React from 'react';
import { IonContent, IonRouterLink, IonButtons, IonBackButton, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';


const FeelingsList: React.FC = () => {
  let { id } = useParams();
  
  return (
    <IonPage className="">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding neuroplasticity-bg">
        <div className="align-bottom">
          <div className="scroll-container">
            <h2>Here's three emotions that often come up for folks.</h2><h2>Does one resonate with you?</h2>
            <div className="button-list">
              <IonRouterLink className="button" routerLink={"/guides/hard-conversations/"+id+"/feelings/anger"} routerDirection="forward">Anger</IonRouterLink>
              <IonRouterLink className="button" routerLink={"/guides/hard-conversations/"+id+"/feelings/shame"} routerDirection="forward">Shame</IonRouterLink>
              <IonRouterLink className="button" routerLink={"/guides/hard-conversations/"+id+"/feelings/anxiety"} routerDirection="forward">Anxiety</IonRouterLink>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default FeelingsList;