import { Plugins } from "@capacitor/core";

const { Storage } = Plugins;

export async function set(key: string, value: any): Promise<void> {
  await Storage.set({
    key: key,
    value: JSON.stringify(value)
  });
}

export async function get(key: string): Promise<any> {
  const item = await Storage.get({ key: key });
  if (item.value != null)
    return JSON.parse(item.value || '{}');
  else
    return ''
}

export async function remove(key: string): Promise<any> {
  await Storage.remove({
    key: key
  });
}