import { Capacitor } from '@capacitor/core';
import { Plugins } from '@capacitor/core';


const KeyboardUtils = {
  showAccessoryBar: () => {
    const isAvailable = Capacitor.isPluginAvailable('Keyboard');
    if (isAvailable) {
      const { Keyboard } = Plugins;
      Keyboard.setAccessoryBarVisible({isVisible: true});
      Keyboard.show();
    }
  }
}

export default KeyboardUtils;