import React from 'react';
import { IonPage, IonHeader, IonToolbar, IonContent, IonButtons, IonBackButton } from '@ionic/react';
import Analytics from '../../../../services/Analytics'


const Anger: React.FC = () => {
  Analytics.logEvent({
    name: "screen_view",
    params : {
      screen_name: "Feelings - Anger"
    }
  });
  return (
    <IonPage className="anger-bg">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className={"ion-padding learn-feelings"}>
            <div className="section">
                <div className="">
                    <h1>Anger</h1>

                    <p>The definition of anger is an emotion that is characterized by antagonism toward someone or something you feel has deliberately done you wrong (American Psychological Association). Understanding that anger is about perceived injustice is important because it helps you differentiate it from emotions that might feel similar. For example, disappointment is about unmet expectations, whereas anxiety is about uncertainty.</p>

                    <p>Anger can be a confusing emotion because you feel it really strongly, it can feel hard to control, and it can cloud your thinking. Emotions are the result of experience and interpretation. First, you experience an event, your brain begins to processes it, and then you feel angry. Your body goes through that process really quickly. Anger is associated with you appraising the situation and identifying blame/injustice, which often motivates approach and attack behaviors. While you may not physically approach or attack someone, the emotion is telling you to gear up and plan for threats because you perceive someone has deliberately done you wrong. Anger can also be a positive, motivating emotion. Since it tends to be highly activating, it can be really productive to push us towards goals, to resolve the injustice we feel, and to advocate for ourselves.</p>

                    <p>Anger is a self-protective emotion. The function of anger is to identify violations to your well-being, which can be either physical threats or emotional threats. The brain process someone raising a fist to hit you the same way it processes emotional threats like rejection, exclusion, or humiliation. To protect you from harm and   injustice, which make you vulnerable, your brain short cuts to anger. However, anger is an Iceberg emotion, which means that anger is often not really about anger. When we feel we are the victim of an injustice, that likely evokes far more vulnerable emotions that are harder to manage, so your brain decides anger is easier. Vulnerable emotions that are commonly "below" anger are things like embarrassment, shame, humiliation, disappointment, guilt, hurt, sadness, frustration, anxiety, uncertainty, etc.</p>

                    <p>Anger is hard because it can cloud your vision. Since anger is a function of self-protection, that makes it hard to manage in the moment. It can be difficult to stop and think about others or the larger context of a situation when we are feeling a lot of anger because our bodies are sending chemicals telling us to prioritize our well-being. Anger is a powerful emotion that creates cognitive bias where we favor our interpretations of events. This creates confirmation bias that makes us feel like we are in the right no matter what, shuts down communication, and escalates conflict. This is important information to remember in the context of hard conversations because if you are angry, or both parties are angry, this conversation is not likely to be productive in the moment because you, or both of you, are coming from self-protective ego positions that make you less open.</p>

                    <p>Anger is also an emotion that is heavily linked to bias. Folks who identify as male are socialized to believe anger is the only acceptable emotion they can display publicly. Folks who identify as female are socialized to avoid outward displays of anger, otherwise they run the risk of being labeled a hysterical bitch. White people are allowed to express anger much more freely than Black people and other people of color. How we are socialized into our identity has major repercussions for how we think about and display anger. Bias also heavily dictates who is punished for expressing their anger. Carry this thought with you when you feel angry or are interacting with someone who is angry.</p>

                    <p>Everyone experiences anger and struggles with this emotion. Everyone has also received very specific social messaging about the acceptability of their anger, as well as the more vulnerable emotions that often underly it. When you are struggling with anger get some space from the person or event that is upsetting you. Take a walk and a few deep breaths. Get curious about your emotions and dissect the event that made you feel violated. Make a quick mental list of the other emotions you are feeling below anger. Being able to name your emotions is the first step to being able to tame your emotions. Practice observing your emotions without judgement and remember, emotions are like tunnels. You have to go through them to get out of them.</p>
                </div>
            </div>
        </IonContent>
    </IonPage>
  );
};

export default Anger;
