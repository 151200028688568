import React from 'react';
import { IonPage, IonHeader, IonToolbar, IonContent, IonButtons, IonBackButton } from '@ionic/react';
import Analytics from '../../../../services/Analytics'

const Shame: React.FC = () => {
  Analytics.logEvent({
    name: "screen_view",
    params : {
      screen_name: "Feelings - Shame"
    }
  });
  return (
    <IonPage className="shame-bg">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/home" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className={"ion-padding learn-feelings"}>
            <div className="section">
                <div className="">
                  <h1>SHAME</h1>
                  <p>The definition of Shame is "The intensely painful feeling or experience of believing that we are flawed and therefore unworthy of love and belonging" (Brown, 2012). Shame is the fear of disconnection.</p>
                  <p>We are psychologically programmed to believe that we need other people to survive; it is part of our evolutionary legacy. When we fear we have done something that makes us unworthy of connection, it is deeply painful and scary. It feels like a threat to our survival, even if we are not conscious that we are processing it that way. Our brains process emotional pain the same way it processes physical pain. The pain of social rejection, whether you have been rejected or are worried you are going to be, hurts with the same intensity as getting punched in the face.</p>
                  <p>Shame is commonly felt in the context of hard conversations because these conversations are directly linked to how other's perceive us. Hard conversations make you worry because they are directly linked to your sense of belonging. You fear you will lose connection and be seen as unworthy in the eyes of one person or a whole organization if they go poorly or we upset someone with power.</p>
                  <p>Shame is not an easy emotion to feel. It creeps in when you feel vulnerable to whisper scary things full of self-doubt and self-criticism. Shame wants you to believe you need the validation of others to think you are worthy. It corrodes your sense of worth and makes you less likely to take risks, try new things, or stand up for your boundaries. Shame makes you feel unworthy and tells you that your ideas are not valuable. It can also make you more likely to protect yourself by blaming others, lying, and avoiding accountability. Shame can cause physical pain, make us shrink our posture, and primes you to doubt yourself.</p>
                  <p>Dr. Linda Hartling, an expert in the study of humiliation, outlines how our behavior is impacted by the feeling of, or threat of, Shame. Some folks move away from shame by withdrawing, silencing, and making ourselves small. Others move toward shame by seeking to appease and please instead of advocate. Still others move against shame by trying to gain power over folks, being super aggressive and domineering, and weaponizing shame to humiliate others. Some may do a combination of all of the above.</p>
                  <p>It is also important to note gender socialization plays a big role in how folks experience shame. Those who identify as female have been socialized to always be "nice" and are expected to be perfect without looking like they are working too hard for it. Hard conversations involve standing up for yourself, potentially causing conflict, and publicly striving, which makes them a breeding ground for shame. Those who identify as male have been socialized to live under the unrelenting pressure to never look weak. That message starts in childhood and follows folks who identify as male their whole lives. That also translates into why hard conversations can trigger shame for men, they require vulnerability, which can be misperceived as weakness.</p>
                  <p>Shame is a hard emotion to feel, primarily because it wants you to feel like you are alone in your fears of disconnection, but that is bullshit. Everyone struggles with shame. We have to name it to tame it. Shame researcher Brene Brown says it best, "Shame derives its power from being unspeakable... Shame hates having words wrapped around it. If we speak shame, it begins to wither." Acknowledge that you're feeling shame, then wrap it up in self-compassion, connection, and empathy.</p>
                </div>
            </div>
        </IonContent>
    </IonPage>
  );
};

export default Shame;
