import React from 'react';
import { IonPage, IonHeader, IonToolbar, IonContent, IonButtons, IonBackButton } from '@ionic/react';
import Analytics from '../../../../services/Analytics'


const Anxiety: React.FC = () => {
  Analytics.logEvent({
    name: "screen_view",
    params : {
      screen_name: "Feelings - Anxiety"
    }
  });
  return (
    <IonPage className="anxiety-bg">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className={"ion-padding learn-feelings"}>
            <div className="section">
                <div className="">
                    <h1>Anxiety</h1>
                    <p>The fancy—official definition of Anxiety is "a state of unease about a distal, potentially negative outcome that is uncertain or unpredictable" (Lake & Labar, 2011). Anxiety is part of your brain's defensive motivation system.</p>
                    <p>This definition just means Anxiety is the emotion that comes up when you're feeling uneasy about something in the future. It's a physiological response to uncertainty.</p>
                    <p>Your body does not like uncertainty because it wants to be able to predict which resources you are going to need, and it can't if it doesn't know what's coming. Anxiety is different than fear in three important ways: it is longer lasting, it's more concerned about the future than the present, and it often has a less specific origin point.</p>
                    <p>For example, fear gets activated by something really specific, like a stranger breaking into your home. Then, you realize it's your partner and they just forgot their keys. Boom, fear is gone.</p>
                    <p>Anxiety get activated by less specific stimuli, like wondering if your boss thought your presentation sucked. Maybe your co-worker tells you you killed it and the boss was smiling the whole time, but rather than going away, your anxiety keeps lingering because you feel uncertain.</p>
                    <p>Anxiety is common during hard conversations because there is so much uncertainty about how they will go, how you will feel, how others will react, and what the outcome will be.</p>
                    <p>Anxiety results in your brain making tons of predictions to try and guess what is next and what the outcome is going to be. Your brain starts doing all kinds of things when it's trying to react to uncertainty. Your mind starts racing, you start fixating on things trying to predict the outcome, you start trying to fill in the blanks for a million different scenarios, you jump to conclusions without evidence. Your pulse might race, you might sweat a little, your hands might shake, you might feel your heart beating a little faster, you might have trouble focusing. While annoying, this emotion is trying to do you a favor by coming up with plans so your brain can allocate resources and stop feeling uncertain.</p>
                    <p>Anxiety can impact your ability to view things clearly because your brain is coming from a protective stance. It wants to care for you, which is why it's making you think through all these scenarios, or ruminate, or attempt mental time travel. Anxiety tends to turn up the volume on doubt, which can mess with your confidence. When you feel anxious, name it. Unnamed emotions escalate, which is why you can jump from anxiety to panic real quick.</p>
                    <p>Anxiety is a very normal, healthy emotion. It can also be hard to feel. Anxiety itself is not bad for you, being STUCK in Anxiety is what is bad for you. Feeling this emotion does not mean there is something wrong with you. Try not to judge your anxiety, just observe it. Everyone feels this way sometimes. Remember that anxiety messes with your perceptions. Feelings are important data, but they are not facts.</p>
                    <p>Anxiety is a physical response to uncertainty, and it craves a physical response. You have more control over this emotion than it feels like you do. Take deep breaths, flex and release your fingers and toes, take a walk, do jumping jacks. Moving your body in response to Anxiety is crucial. Take a walk, and a few big breaths, and remind yourself this is a normal emotion to feel. Get curious about the origin, be kind to yourself, and remind yourself that when the Anxiety is really turning up the volume, you control the knob.</p>
                </div>
            </div>
        </IonContent>
    </IonPage>
  );
};

export default Anxiety;
