import React from 'react';
import { IonContent, IonRouterLink, IonButtons, IonBackButton, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';

const PrepareList: React.FC = () => {
  let { id } = useParams();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        <div className="align-bottom">
          <div className="scroll-container">
            <h2>Preparing for a hard conversation is important. Here’s some tools to help you.</h2>
            <div className="button-list">
              <IonRouterLink className="button" routerLink={"/guides/hard-conversations/"+id+"/prepare/make-a-list"} routerDirection="forward">Make a List</IonRouterLink>
              <IonRouterLink className="button" routerLink={"/guides/hard-conversations/"+id+"/prepare/battle-shame"} routerDirection="forward">Battle Shame</IonRouterLink>
              <IonRouterLink className="button" routerLink={"/guides/hard-conversations/"+id+"/prepare/breathe"} routerDirection="forward">Breathe</IonRouterLink>
              <IonRouterLink className="button" routerLink={"/guides/hard-conversations/"+id+"/prepare/define-boundaries"} routerDirection="forward">Define Boundaries</IonRouterLink>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PrepareList;